

import React from 'react';
import classNames from 'classnames';
import AppAlertIndicator from 'src/components/common/app-alert-indicator';
import Link from 'src/components/router/link';

const clsPrefix = 'app-entitlement-alert-indicator';

interface EntitlementAlertIndicatorProps {
  className?: string;
  onClick?: () => void;
}

const EntitlementAlertIndicator: React.FC<EntitlementAlertIndicatorProps> = ({
  className,
  onClick,
}) => {
  return (
    <AppAlertIndicator
      className={classNames(clsPrefix, className)}
      message={
        <div>
          Permission or membership verification needed to generate these forms
          <br />
          <Link
            className={`${clsPrefix}__link`}
            routeName="account.integrations"
            onClick={onClick}
          >
            Review now
          </Link>
        </div>
      }
    />
  );
};

export default EntitlementAlertIndicator;
