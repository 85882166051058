

import React, { Component } from 'react';
import { Alert, Col, Form, Input, Row, FormInstance } from 'antd';
import api from 'src/api/public-api';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import type UiStore from 'src/stores/ui-store';
import type { AssociationMembership } from 'src/types/proto/brokers';
import type { MlsMembership } from 'src/types/proto/integrations';
import type { Provider } from 'src/entries/admin/providers/models';
import type { AnalyticsContext } from './types';
import type { EnrichedAssociation } from './get-associations';
import type AccountStore from 'src/stores/account-store';
import { trackProvidersConnected } from './analytics';

const clsPrefix = 'app-nrds-verify-membership-modal';

interface Props {
  account: AccountStore;
  organization: EnrichedAssociation;
  ui?: UiStore;
  loadForms?: (() => void) | null;
  onCancel: () => void;
  onVerified: () => void;
  analyticsContext: AnalyticsContext;
}

interface State {
  apiError: any;
  verified: boolean;
  visible: boolean;
}

export default class GARVerifyMembershipModal extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();
  state: State = {
    apiError: null,
    verified: false,
    visible: true,
  };

  verifyId = async () => {
    const garId = this.formRef.current?.getFieldValue('garId');
    if (!garId) {
      return;
    }

    const {
      account,
      loadForms,
      organization,
      ui,
      onVerified,
      analyticsContext,
    } = this.props;
    try {
      const { data: memberships } = await api.orgs.verifyNrdsId(organization, {
        garId,
      });
      const verifiedProviders = memberships.map(
        (m) =>
          ((m as AssociationMembership).association ||
            (m as MlsMembership).mlsOrg) as Provider
      );
      trackProvidersConnected({
        verifiedProviders,
        targetProvider: organization,
        analyticsContext,
      });
      account.refreshOwnAssociations();

      ui?.toast({
        type: 'success',
        message: `Successfully added ${organization.title}`,
      });

      this.setState({
        verified: true,
      });

      if (onVerified) {
        onVerified();
      }

      if (loadForms) {
        loadForms();
      }
    } catch (err: any) {
      this.setState({
        apiError: err.message || 'An unexpected error occurred.',
      });
    }
  };

  render() {
    const { organization, onCancel } = this.props;
    const { verified, visible } = this.state;

    return (
      <AppModal
        visible={visible && !verified}
        title="Verify your membership"
        cancelable
        onCancel={() =>
          this.setState({
            visible: false,
          })
        }
        footer={
          <div>
            <AppButton onClick={onCancel}>Cancel</AppButton>
            <AppButton type="primary" onClick={this.verifyId}>
              Verify GAR ID
            </AppButton>
          </div>
        }
      >
        {this.state.apiError && (
          <React.Fragment>
            <Alert message={this.state.apiError} type="error" />
            <br />
          </React.Fragment>
        )}
        <p>
          <strong>{organization.title}</strong> wants to verify your membership
          before providing access to their forms.
        </p>

        <Form
          onFinish={this.verifyId}
          ref={this.formRef}
          fields={[
            {
              name: ['garId'],
              value: '',
            },
          ]}
        >
          <div className={`${clsPrefix}__nrds-id`}>
            <Row>
              <Col xs={24} sm={12}>
                GAR ID
              </Col>
            </Row>
            <Form.Item name="garId">
              <Input data-testid="garIdInput" />
            </Form.Item>
          </div>
        </Form>
      </AppModal>
    );
  }
}
