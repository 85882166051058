import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import closeIcon from 'src/images/icons/close.svg';
import AnchorButton from './anchor-button';
import AppIcon from './app-icon';


interface IconProps {
  type: string;
  svg?: string;
  name?: string;
  size?: number;
}

interface Props {
  iconProps?: IconProps;
  description?: string;
  actionButton?: React.ReactNode;
  onClose?: VoidFunction;
  className?: string;
  children?: ReactNode;
}

const clsPrefix = 'app-actionable-banner';

const ActionableBanner: FC<Props> = ({
  iconProps,
  description,
  actionButton,
  children,
  onClose,
  className,
}) => {
  return (
    <div className={classNames(clsPrefix, className)}>
      <AppIcon {...iconProps} className={`${clsPrefix}__icon`} />
      <div className={`${clsPrefix}__content`}>
        <div className={`${clsPrefix}__action-content`}>
          <div className={`${clsPrefix}__description`}>{description}</div>
          {actionButton}
          {children}
        </div>
      </div>
      {!!onClose && (
        <AnchorButton
          onClick={onClose}
          className={`${clsPrefix}__close-button`}
        >
          <AppIcon type="svg" size={16} svg={closeIcon} />
        </AnchorButton>
      )}
    </div>
  );
};

export default ActionableBanner;
