export const TWO_LETTER = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

// Source: https://en.wikipedia.org/wiki/List_of_states_and_territories_of_the_United_States
export const stateCodeNameMap = [
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DC', 'District of Columbia'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
];

const statesMap = new Map(stateCodeNameMap);

export function getStateFullName(stateCode) {
  return statesMap.get(stateCode);
}

// Warning: some states are in more than one timezone.
// This mapping picks the timezone that covers the biggest population centers.
// (eg in Tennessee we pick Central time to handle Nashville correctly,
//  but Knoxville is in Eastern.)
export const TIMEZONES_BY_STATE = {
  AK: {
    name: 'US/Alaska',
    suffix: 'AKST',
  },
  AL: {
    name: 'US/Central',
    suffix: 'CST',
  },
  AR: {
    name: 'US/Central',
    suffix: 'CST',
  },
  AZ: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  CA: {
    name: 'US/Pacific',
    suffix: 'PST',
  },
  CO: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  CT: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  DC: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  DE: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  FL: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  GA: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  HI: {
    name: 'Pacific/Honolulu',
    suffix: 'HST',
  },
  IA: {
    name: 'US/Central',
    suffix: 'CST',
  },
  ID: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  IL: {
    name: 'US/Central',
    suffix: 'CST',
  },
  IN: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  KS: {
    name: 'US/Central',
    suffix: 'CST',
  },
  KY: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  LA: {
    name: 'US/Central',
    suffix: 'CST',
  },
  MA: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  MD: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  ME: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  MI: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  MN: {
    name: 'US/Central',
    suffix: 'CST',
  },
  MO: {
    name: 'US/Central',
    suffix: 'CST',
  },
  MS: {
    name: 'US/Central',
    suffix: 'CST',
  },
  MT: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  NC: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  ND: {
    name: 'US/Central',
    suffix: 'CST',
  },
  NE: {
    name: 'US/Central',
    suffix: 'CST',
  },
  NH: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  NJ: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  NM: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  NV: {
    name: 'US/Pacific',
    suffix: 'PST',
  },
  NY: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  OH: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  OK: {
    name: 'US/Central',
    suffix: 'CST',
  },
  OR: {
    name: 'US/Pacific',
    suffix: 'PST',
  },
  PA: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  RI: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  SC: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  SD: {
    name: 'US/Central',
    suffix: 'CST',
  },
  TN: {
    name: 'US/Central',
    suffix: 'CST',
  },
  TX: {
    name: 'US/Central',
    suffix: 'CST',
  },
  UT: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
  VA: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  VT: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  WA: {
    name: 'US/Pacific',
    suffix: 'PST',
  },
  WI: {
    name: 'US/Central',
    suffix: 'CST',
  },
  WV: {
    name: 'US/Eastern',
    suffix: 'EST',
  },
  WY: {
    name: 'US/Mountain',
    suffix: 'MST',
  },
};
