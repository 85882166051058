

import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import AppIcon from 'src/components/common/app-icon';

const clsPrefix = 'app-alert-indicator';

interface AppAlertIndicatorProps {
  message?: React.ReactNode;
  className?: string;
}

const AppAlertIndicator: React.FC<AppAlertIndicatorProps> = ({
  className,
  message,
}) => {
  const alertIcon = (
    <div className={classNames(clsPrefix, className)} data-testid="alertIcon">
      <AppIcon className={`${clsPrefix}__icon`} name="alert-circle" size={16} />
    </div>
  );
  if (!message) {
    return alertIcon;
  }

  return <Tooltip title={message}>{alertIcon}</Tooltip>;
};

export default AppAlertIndicator;
