import {
  type FormMembershipVerifiedProps,
  type FormProviderConnectedProps,
  formLibraryAdded,
  formMembershipVerified,
  formProviderConnected,
} from '@uc/analytics-definitions';
import type { Provider } from 'src/entries/admin/providers/models';
import logger from 'src/logger';
import type { EnrichedAssociation } from './get-associations';
import type { AnalyticsContext } from './types';

interface TrackProvidersConnectedOptions {
  targetProvider: EnrichedAssociation;
  verifiedProviders: Provider[];
  analyticsContext: AnalyticsContext;
}

export const trackProvidersConnected = async ({
  targetProvider,
  verifiedProviders,
  analyticsContext,
}: TrackProvidersConnectedOptions) => {
  try {
    const analyticsProperties: FormProviderConnectedProps &
      FormMembershipVerifiedProps = {
      product: 'documents',
      sub_product: 'forms',
      provider_id: verifiedProviders.map((org) => org.id),
      provider_name: verifiedProviders.map((org) => org.title),
      product_path: analyticsContext.productPath,
    };

    const verificationKind = targetProvider?.verificationMethod?.kind || 'NONE';

    if (verificationKind !== 'NONE') {
      formMembershipVerified(analyticsProperties);
    }

    if (!targetProvider?.isVerified && !targetProvider?.isVerificationLapsed) {
      formProviderConnected(analyticsProperties);
    }

    formLibraryAdded({
      product: 'documents',
      sub_product: 'forms',
    });
  } catch (e) {
    // Do not block the UI from continuing if we could not build/trigger the analytics events
    logger.error(e);
  }
};
