

import React, { Component } from 'react';
import { Alert, Form, FormInstance } from 'antd';
import api from 'src/api/public-api';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import type AccountStore from 'src/stores/account-store';
import type UiStore from 'src/stores/ui-store';
import type { EnrichedAssociation } from './get-associations';
import type { AnalyticsContext } from './types';
import { trackProvidersConnected } from './analytics';

interface Props {
  account?: AccountStore;
  organization: EnrichedAssociation;
  ui?: UiStore;
  loadForms?: () => void;
  onCancel: () => void;
  onVerified: () => void;
  analyticsContext: AnalyticsContext;
}

interface State {
  apiError: any;
  verified: boolean;
  visible: boolean;
}

export default class SelfVerifyMembershipModal extends Component<Props, State> {
  formRef = React.createRef<FormInstance>();
  state: State = {
    apiError: null,
    verified: false,
    visible: true,
  };

  verifyId = async () => {
    const {
      account,
      loadForms,
      organization,
      ui,
      onVerified,
      analyticsContext,
    } = this.props;
    try {
      await api.orgs.verifyNrdsId(organization);
      account?.refreshOwnAssociations();
      trackProvidersConnected({
        targetProvider: organization,
        verifiedProviders: [organization],
        analyticsContext,
      });

      ui?.toast({
        type: 'success',
        message: `Successfully added ${organization.title}`,
      });

      this.setState({
        verified: true,
      });

      if (onVerified) {
        onVerified();
      }

      if (loadForms) {
        loadForms();
      }
    } catch (err: any) {
      this.setState({
        apiError: err.message || 'An unexpected error occurred.',
      });
    }
  };

  unlink = async () => {
    const { account, organization, ui, onCancel, onVerified } = this.props;
    const isMember = await account?.isMemberFromAssociation(organization);
    if (!isMember) {
      onCancel();
      return;
    }
    try {
      await api.orgs.unlinkAssociation(organization);
      account?.refreshOwnAssociations();

      ui?.toast({
        type: 'success',
        message: `Unlinked ${organization.title}`,
      });
      this.setState({
        visible: false,
      });
      onVerified();
    } catch (err: any) {
      this.setState({
        apiError: err.message || 'An unexpected error occurred.',
      });
    }
  };

  render() {
    const { organization } = this.props;
    const { verified, visible } = this.state;
    const verifyText = `I certify that I am a member of ${organization.title} in good standing and am entitled to access fillable digital forms offered by this form provider.`;

    return (
      <AppModal
        visible={visible && !verified}
        title="Certify your membership"
        cancelable
        onCancel={() =>
          this.setState({
            visible: false,
          })
        }
        footer={
          <div>
            <AppButton onClick={this.unlink}>No, I am not a member</AppButton>
            <AppButton type="primary" onClick={this.verifyId}>
              Yes, certify membership
            </AppButton>
          </div>
        }
      >
        {this.state.apiError && (
          <React.Fragment>
            <Alert message={this.state.apiError} type="error" />
            <br />
          </React.Fragment>
        )}
        <p>{verifyText}</p>

        <Form onFinish={this.verifyId} ref={this.formRef} />
      </AppModal>
    );
  }
}
