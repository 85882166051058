

import React, { useEffect, useState } from 'react';
import publicApi from 'src/api';
import AppIcon from 'src/components/common/app-icon';
import AppModal from 'src/components/common/app-modal';
import RowSpinner from 'src/components/common/row-spinner';
import type { Provider } from 'src/entries/admin/providers/models';

const clsPrefix = 'app-associations-linked-modal';

interface Props {
  onClose: () => void;
  associations: Provider[];
}

const AssociationLinkedModal: React.FC<Props> = (props) => {
  const { onClose, associations } = props;

  const [loading, setLoading] = useState(true);
  const [formCountByLibrary, setFormCountByLibrary] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const loadLibraries = async () => {
      const libraryUuids = associations
        .map((a) => a.libraryUuid)
        .filter(Boolean);
      const { data: formCountByLibraryUuids } =
        await publicApi.forms.getFormCountByLibraryUuids(libraryUuids);

      setFormCountByLibrary(formCountByLibraryUuids);
      setLoading(false);
    };
    loadLibraries();
  }, []);

  const renderFormCount = (libraryUuid: string) => {
    if (loading) {
      return <RowSpinner height="24px" style={{}} />;
    }
    const formCount = formCountByLibrary[libraryUuid] ?? 0;
    return `(${formCount} form${formCount > 1 ? 's' : ''})`;
  };

  return (
    <AppModal
      title="We’ve found more forms you have access to"
      okText="Done"
      onOk={onClose}
      className={clsPrefix}
      width={650}
      cancelable={false}
      visible
    >
      <div className={`${clsPrefix}__text`}>
        Your membership also gives you access to these additional form
        libraries:
      </div>
      {associations.map((association) => (
        <div key={association.id} className={`${clsPrefix}__association`}>
          <span className={`${clsPrefix}__association-icon`}>
            <AppIcon type="cx" name="circleWithCheckmark" />
          </span>
          <span className={`${clsPrefix}__association-title`}>
            {association.title}
          </span>
          <span className={`${clsPrefix}__association-forms`}>
            {renderFormCount(association.libraryUuid)}
          </span>
        </div>
      ))}
    </AppModal>
  );
};

export default AssociationLinkedModal;
