import React, { FC } from 'react';
import NRDSVerifyMembershipModal, {
  NRDSVerifyMembershipModalProps,
} from './nrds-verify-membership-modal';

const NJVerifyMembershipModal: FC<NRDSVerifyMembershipModalProps> = (
  props
) => {
  const { account } = props;
  return (
    <NRDSVerifyMembershipModal
      {...props}
      onBeforeSubmit={({ nrdsId, lastName }) =>
        account.setNJApiCredentials(nrdsId, lastName)
      }
    />
  );
};

export default NJVerifyMembershipModal;
